import { useAuth } from "../context/AuthContext";
import { Navigate, Outlet, useResolvedPath } from "react-router-dom";

const TutorialRoute = () => {
  let { user } = useAuth();
  const { pathname } = useResolvedPath();
  return user.setup === 1 && pathname === "/tutorial" ? (
    <Navigate to="/dashboard" />
  ) : (
    <Outlet />
  );
};

export default TutorialRoute;
