import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ResultIcon from "../Display/ResultIcon";
import ClearIcon from "@mui/icons-material/Clear";
import {
  CenteredColumn,
  SpacedRow,
  StyledText,
  VerticalCenteredRow,
} from "../../data/style/style";

const Notification = (props) => {
  let { item, shutDownNotification } = props;

  return (
    <Stack direction={"row"}>
      <Stack gap={0.5} width={"100%"}>
        <SpacedRow>
          <VerticalCenteredRow gap={0.5}>
            <ResultIcon type_id={item.type_id} />
            <StyledText
              sx={{
                fontSize: "0.9rem !important",
                color:
                  item.type_id === 0
                    ? "#af3a2d !important"
                    : item.type_id === 1
                    ? "#3098D5 !important"
                    : "#2daf64 !important",
              }}
              card={"True"}
            >
              {item.title}
            </StyledText>
          </VerticalCenteredRow>
          <StyledText sx={{ fontSize: "0.7rem !important" }}>
            {item.created_at}
          </StyledText>
        </SpacedRow>
        <SpacedRow>
          <Box width={"95%"}>
            <StyledText>
              {item.message.length > 120 ? (
                <>{item.message.slice(0, 120) + "..."}</>
              ) : (
                <>{item.message}</>
              )}
            </StyledText>
          </Box>
          <CenteredColumn height={"100%"}>
            <ClearIcon
              onClick={() => shutDownNotification(item.id)}
              color="secondary"
              sx={{
                fontSize: "1.2rem !important",
                cursor: "pointer",
              }}
            />
          </CenteredColumn>
        </SpacedRow>
        <Divider />
      </Stack>
    </Stack>
  );
};

export default Notification;
