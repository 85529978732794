import { useAuth } from "../context/AuthContext";
import { Navigate, Outlet, useResolvedPath } from "react-router-dom";

const UserRoute = () => {
  let { user } = useAuth();
  const { pathname } = useResolvedPath();

  return user.level === 0 &&
    pathname !== "/dashboard" &&
    pathname !== "/conversations" &&
    pathname !== "/profile" &&
    pathname !== "/support" ? (
    <Navigate to="/conversations" />
  ) : (
    <Outlet />
  );
};

export default UserRoute;
