import { forwardRef } from "react";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import {
  CenteredColumn,
  ResponseBox,
  StyledText,
} from "../../data/style/style";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
const ResponseBadge = forwardRef((props, ref) => {
  let { open, requestStatus, responseText } = props;
  return (
    <Snackbar
      open={open}
      sx={{ marginBottom: "50px" }}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <ResponseBox ref={ref} status={requestStatus}>
        <CenteredColumn minHeight={"40px"}>
          {requestStatus === 2 && (
            <StyledText success={"true"}>{responseText}</StyledText>
          )}
          {requestStatus === 3 && (
            <StyledText fail={"true"}>{responseText}</StyledText>
          )}
          {props.requestStatus === 4 && (
            <StyledText info={"true"}>{responseText}</StyledText>
          )}
        </CenteredColumn>
      </ResponseBox>
    </Snackbar>
  );
});
export default ResponseBadge;
