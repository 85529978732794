import { Theme } from "../../data/style/Theme";
import { CenteredColumn } from "../../data/style/style";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = (props) => {
  let { height, minHeight } = props;
  return (
    <CenteredColumn
      height={height + "vh"}
      minHeight={minHeight + "px"}
    >
      <CircularProgress
        size={"30px"}
        sx={{ color: `${Theme.palette.blue.light}` }}
      />
    </CenteredColumn>
  );
};

export default Loader;
