import dayjs from "dayjs";
import { useEffect } from "react";
import { StyledText } from "../data/style/style";

const DateHeader = () => {
  var date = dayjs();

  useEffect(() => {
    const interval = setInterval(1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <StyledText dashboard="true">
      {date.format("hh:mm A") +
        " at " +
        date.format("D") +
        "th " +
        date.format("MMMM YYYY")}
    </StyledText>
  );
};

export default DateHeader;
