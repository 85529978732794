import { Theme } from "../style/Theme";

export const csat_data = [
  { name: "Needs improvement", value: 90, color: "#EA3A3D" },
  { name: "Good", value: 36, color: "#FFC100" },
  {
    name: "Great",
    value: 36,
    color: Theme.palette.green.lighter,
  },
  { name: "Excellent", value: 18, color: "#0DAF02" },
];

export const ces_data = [
  { name: "Needs improvement", value: 72, color: "#EA3A3D" },
  { name: "Good", value: 36, color: "#FFC100" },
  {
    name: "Great",
    value: 36,
    color: Theme.palette.green.lighter,
  },
  { name: "Excellent", value: 36, color: "#0DAF02" },
];

export const nps_data = [
  { name: "Needs improvement", value: 90, color: "#EA3A3D" },
  { name: "Good", value: 27, color: "#FFC100" },
  {
    name: "Great",
    value: 36,
    color: Theme.palette.green.lighter,
  },
  { name: "Excellent", value: 27, color: "#0DAF02" },
];

export const PlanModes = {
  new_plan: "newPlan",
  change_plan: "changePlan",
  add_seats: "addSeats",
};

export const APIExamples = {
  authentication:
    '{\n "Content-Type":"application/json"\n "Authorization":"Bearer {access token}"\n}',
  customer:
    '{\n  "email":"JoeDoe@mail.com",\n  "first_name":"Joe",\n  "last_name":"Doe",\n  "custom_id":"12345678",\n  "logo":"https://www.photoUrl.com",\n  "identifiers":[\n                 {\n                  "type":"id",\n                  "value":"1111111111111111",\n                  "channel":"facebook"\n                 }\n                ],\n  "labels":[\n            {\n             "label":"Age",\n             "value":24\n            }\n           ],\n}',
  customer_response:
    '{\n  "custom_id": "12345678",\n  "status": 201,\n  "message": "Customer stored successfully."\n}',
  update_customer_response:
    '{\n  "custom_id": "12345678",\n  "status": 200,\n  "message": "Customer updated successfully."\n}',
};

export const APIUrls = {
  customer: process.env.REACT_APP_DOMAIN + "apis/customer",
};

export const formErrors = {
  required_field: "This field is required.",
  invalid_email: "Invalid email format.",
  invalid_queue: "Invalid queue format.",
  invalid_first_name: "Invalid first name format.",
  invalid_last_name: "Invalid last name format.",
  invalid_password:
    "Invalid password. Must be at least 8 characters, up to 20 characters, at least one number and at least one symbol.",
  different_passwords: "Passwords do not match.",
  invalid_phone:
    "Phone must be numeric and between 5 and 15 characters.",
  invalid_mobile:
    "Mobile must be numeric and between 5 and 15 characters.",
  invalid_label: "Label must be between 1 and 100 characters.",
  accept_terms: "You have to accept terms and conditions.",
  invalid_display_name:
    "Display name must be up to 30 characters and not contain spaces.",
  inactivity_error:
    "Inactivity time must not be smaller than 5 minutes.",
  max_ticket_error:
    "Max tickets per user can not be negative number.",
  // keep_alive_error: "Keep alive number can not be negative number.",
  user_email_error:
    "There is an account already associated with this email.",
};

export const systemErrors = {
  system_error: "System error. Please try again.",
  unauthorized: "Session expired. Please log in again.",
  user_level_error: "Unauthorized access. Please contact your admin.",
  plan_error: "Feature unavailable. Please upgrade your plan.",
  seats_error:
    " Your plan does not support more concurrent users. Please contact you admin.",
  history_error:
    "This contact has an existing ticket history and cannot be deleted.",
  bhours_active_day_error:
    "Businesses must have at least one active day.",
  verify_email_error: "Error while trying to verify your email.",
};

export const planErrors = {
  max_queues_error:
    "Your plan does not support more queues. Please consider upgrading your plan.",
  max_channel_accounts_error:
    "Your plan does not support more channel accounts. Please consider upgrading your plan.",
  max_channels_error:
    "Your plan does not support more channels. Please consider upgrading your plan.",
  max_token_error:
    "You have reach the maximum allowed number of tokens.",
  playlist_error:
    "Feature unavailable. Please wait for previous process to complete.",
};

export const ExistsErrors = {
  skill: "Skill already exists.",
  language: "Language already exists.",
  queue: "Queue already exists.",
  identifier: "Identifier already exists.",
  channel: "Channel already exists.",
  user: "User already exists.",
  contact: "Contact already exists.",
  label: "Label already exists.",
  category: "Category already exists.",
};

export const ImageErrors = {
  profile_width:
    "The width of the image is out of range [200px, 3000px]. Please select another image.",
  profile_height:
    "The height of the image is out of  range [200px, 3000px]. Please select another image.",
  org_width:
    "The width of the image is out of range [300px, 3000px]. Please select another image.",
  org_height:
    "The height of the image is out of  range [300px, 3000px]. Please select another image.",
  img_size:
    "The size of the image exceeds the  limit of 3MB. Please select another image.",
};

export const fileErrors = {
  required_file: "File is required.",
  file_size:
    "The size of the file exceeds the  limit of 10MB. Please select another file.",
  multiple_files:
    "Multiple file attachments are not supported. Please attach only one file at a time.",
};

export const mergeContactErrors = {
  default_error: "Please select a default contact to merge.",
  one_contact_error: " Please select at least two contacts to merge.",
};

export const PageTitles = {
  login: "Business Conversations | Mobiweb",
  register: "Business Conversations | Mobiweb - Register",
  recover_password: "Business Conversations | Mobiweb - Recover",
  reset_password: "Business Conversations | Mobiweb - Reset",
  verify: "Business Conversations | Mobiweb - Verify",
  set_up: "Business Conversations | Mobiweb - Set Up",
  tutorial: "Tutorial - Business Conversations | Mobiweb",
  dashboard: "Dashboard - Business Conversations | Mobiweb",
  conversations: "Conversations - Business Conversations | Mobiweb",
  contacts: "Contacts - Business Conversations | Mobiweb",
  statistics: "Statistics - Business Conversations | Mobiweb",
  reports: "Reports - Business Conversations | Mobiweb",
  ticket_trancripts:
    "Ticket Transcripts - Business Conversations | Mobiweb",
  cx_metrics:
    "Customer Experience Metrics - Business Conversations | Mobiweb",
  cx_statistics:
    "Customer Experience Statistics - Business Conversations | Mobiweb",
  cx_questions:
    "Customer Experience Questions - Business Conversations | Mobiweb",
  channels: "Channels - Business Conversations | Mobiweb",
  queues: "Queues - Business Conversations | Mobiweb",
  users: "Users - Business Conversations | Mobiweb",
  skills: "Skills - Business Conversations | Mobiweb",
  rules: "Rules - Business Conversations | Mobiweb",
  businness_hours:
    "Business Hours - Business Conversations | Mobiweb",
  slas: "SLAs - Business Conversations | Mobiweb",
  automated_replies:
    "Automated Replies - Business Conversations | Mobiweb",
  api_tokens: "API Tokens - Business Conversations | Mobiweb",
  api_docs: "API Docs - Business Conversations | Mobiweb",
  prepared_replies:
    "Prepared Replies - Business Conversations | Mobiweb",
  plans: "Plans - Business Conversations | Mobiweb",
  transactions: "Transactions - Business Conversations | Mobiweb",
  organization: "Organization - Business Conversations | Mobiweb",
  profile: "Profile - Business Conversations | Mobiweb",
  notifications: "Notifications - Business Conversations | Mobiweb",
  support: "Support - Business Conversations | Mobiweb",
  terms: "Terms - Business Conversations | Mobiweb",
  privacy_policy: "Privacy Policy - Business Conversations | Mobiweb",
  error_page: "404 - Business Conversations | Mobiweb",
};

export const responseTexts = {
  bhours_updated: "Business hours updated successfully.",
};
