import { lazy, Suspense } from "react";
import UserRoute from "./utils/UserRoute";
import { Theme } from "./data/style/Theme";
import AdminRoute from "./utils/AdminRoute";
import * as routes from "./data/links/routes";
import PrivateRoute from "./utils/PrivateRoute";
import TutorialRoute from "./utils/TutorialRoute";
import { ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./context/AuthContext";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

const SLAS = lazy(() => import("./pages/Slas"));
const Rules = lazy(() => import("./pages/Rules"));
const Plans = lazy(() => import("./pages/Plans"));
const Login = lazy(() => import("./pages/Login"));
const Users = lazy(() => import("./pages/Users"));
const Terms = lazy(() => import("./pages/Terms"));
const Queues = lazy(() => import("./pages/Queues"));
const Skills = lazy(() => import("./pages/Skills"));
const Tokens = lazy(() => import("./pages/Tokens"));
const Reports = lazy(() => import("./pages/Reports"));
const Profile = lazy(() => import("./pages/Profile"));
const Support = lazy(() => import("./pages/Support"));
const ApiDocs = lazy(() => import("./pages/ApiDocs"));
const Tutorial = lazy(() => import("./pages/Tutorial"));
const Register = lazy(() => import("./pages/Register"));
const Contacts = lazy(() => import("./pages/Contacts"));
const Channels = lazy(() => import("./pages/Channels"));
const SetupPage = lazy(() => import("./pages/SetupPage"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const Statistics = lazy(() => import("./pages/Statistics"));
const VerifyEmail = lazy(() => import("./pages/VerifyEmail"));
const Transactions = lazy(() => import("./pages/Transactions"));
const Organization = lazy(() => import("./pages/Organization"));
const Layout = lazy(() => import("./components/Layouts/Layout"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Conversations = lazy(() => import("./pages/Conversations"));
const BusinessHours = lazy(() => import("./pages/BusinessHours"));
const Notifications = lazy(() => import("./pages/Notifications"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const PreparedReplies = lazy(() => import("./pages/PreparedReplies"));
const RecoverPassword = lazy(() => import("./pages/RecoverPassword"));
const AutomatedReplies = lazy(() =>
  import("./pages/AutomatedReplies")
);
const LoggedLayout = lazy(() =>
  import("./components/Layouts/LoggedLayout")
);
const TicketTranscripts = lazy(() =>
  import("./pages/TicketTranscripts")
);
const CustomerExpMetrics = lazy(() =>
  import("./pages/CustomerExpMetrics")
);
const CustomerExpStatistics = lazy(() =>
  import("./pages/CustomerExpStatistics")
);
const CustomerExpQuestions = lazy(() =>
  import("./pages/CustomerExpQuestions")
);
function App() {
  return (
    <Router>
      <ThemeProvider theme={Theme}>
        <AuthProvider>
          <Suspense>
            <Routes>
              <Route
                element={<CustomerExpQuestions />}
                path={routes.CEX_QUESTIONS}
              />
              <Route path="/" element={<Layout />}>
                <Route
                  index
                  path={routes.LOGIN}
                  element={
                    <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.REACT_APP_RECAPTCHA_KEY
                      }
                    >
                      <Login />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path={routes.REGISTER}
                  element={
                    <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.REACT_APP_RECAPTCHA_KEY
                      }
                    >
                      <Register />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path={routes.SET_UP}
                  element={
                    <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.REACT_APP_RECAPTCHA_KEY
                      }
                    >
                      <SetupPage />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path={routes.RECOVER_PASSWORD}
                  element={
                    <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.REACT_APP_RECAPTCHA_KEY
                      }
                    >
                      <RecoverPassword />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path={routes.RESET_PASSWORD}
                  element={
                    <GoogleReCaptchaProvider
                      reCaptchaKey={
                        process.env.REACT_APP_RECAPTCHA_KEY
                      }
                    >
                      <ResetPassword />
                    </GoogleReCaptchaProvider>
                  }
                />
                <Route
                  path={routes.VERIFY_EMAIL}
                  element={<VerifyEmail />}
                />
                <Route path={routes.TERMS} element={<Terms />} />
                <Route
                  path={routes.POLICY}
                  element={<PrivacyPolicy />}
                />
                <Route element={<ErrorPage />} path={routes.ERROR} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route element={<AdminRoute />}>
                  <Route element={<UserRoute />}>
                    <Route element={<TutorialRoute />}>
                      <Route
                        path={routes.TUTORIAL}
                        element={<Tutorial />}
                      />
                    </Route>
                    <Route element={<LoggedLayout />}>
                      <Route
                        path={routes.DASHBOARD}
                        element={<Dashboard />}
                      />
                      <Route
                        path={routes.ORGANIZATION}
                        element={<Organization />}
                      />
                      <Route
                        path={routes.SKILLS}
                        element={<Skills />}
                      />
                      <Route
                        path={routes.CHANNELS}
                        element={<Channels />}
                      />
                      <Route
                        path={routes.CONTACTS}
                        exact
                        element={<Contacts />}
                      />
                      <Route
                        path={routes.CONTACTS_WITH_PARAM}
                        exact
                        element={<Contacts />}
                      />
                      <Route
                        path={routes.QUEUES}
                        element={<Queues />}
                      />
                      <Route
                        path={routes.STATISTICS}
                        element={<Statistics />}
                      />
                      <Route
                        path={routes.REPORTS}
                        element={<Reports />}
                      />
                      <Route
                        path={routes.TICKET_TRANSCRIPTS}
                        exact
                        element={<TicketTranscripts />}
                      />
                      <Route
                        path={routes.TICKET_TRANSCRIPTS_WITH_PARAM}
                        exact
                        element={<TicketTranscripts />}
                      />
                      <Route
                        path={routes.CEX_METRICS}
                        exact
                        element={<CustomerExpMetrics />}
                      />
                      <Route
                        path={routes.CEX_STATISTICS}
                        element={<CustomerExpStatistics />}
                      />
                      <Route
                        path={routes.PROFILE}
                        element={<Profile />}
                      />
                      <Route
                        path={routes.USERS}
                        exact
                        element={<Users />}
                      />
                      <Route
                        path={routes.USERS_WITH_PARAM}
                        exact
                        element={<Users />}
                      />
                      <Route
                        path={routes.PREPARED_REPLIES}
                        exact
                        element={<PreparedReplies />}
                      />
                      <Route
                        path={routes.CONVERSATIONS}
                        element={<Conversations />}
                      />
                      <Route
                        path={routes.API_TOKENS}
                        element={<Tokens />}
                      />
                      <Route
                        path={routes.API_DOCS}
                        element={<ApiDocs />}
                      />
                      <Route
                        path={routes.NOTIFICATIONS}
                        element={<Notifications />}
                      />
                      <Route
                        path={routes.RULES}
                        element={<Rules />}
                      />
                      <Route
                        path={routes.BUSSINESS_HOURS}
                        element={<BusinessHours />}
                      />
                      <Route path={routes.SLAS} element={<SLAS />} />
                      <Route
                        path={routes.AUTOMATED_REPLIES}
                        element={<AutomatedReplies />}
                      />
                      <Route
                        path={routes.TRANSACTIONS}
                        element={<Transactions />}
                      />
                      <Route
                        path={routes.PLANS}
                        element={<Plans />}
                      />
                      <Route
                        path={routes.SUPPORT}
                        element={<Support />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </AuthProvider>
      </ThemeProvider>
    </Router>
  );
}
export default App;
