export const replyTypes = [
  {
    id: 0,
    name: "Welcome Message",
  },
  {
    id: 1,
    name: "Offline Message",
  },
  {
    id: 2,
    name: "Offline Agent Message",
  },
];

export const report_statuses = [
  {
    id: 0,
    name: "Pending",
  },
  {
    id: 1,
    name: "Completed",
  },
];

export const ticket_statuses = [
  {
    id: 0,
    name: "Open",
  },
  {
    id: 1,
    name: "Closed",
  },
];

export const transaction_types = [
  {
    id: 0,
    name: "Invoice",
  },
  {
    id: 1,
    name: "Payment",
  },
];

export const user_online_statuses = [
  {
    id: 0,
    name: "Offline",
  },
  {
    id: 1,
    name: "Busy",
  },
  {
    id: 2,
    name: "Available",
  },
];

export const user_statuses = [
  {
    id: 1,
    name: "Pending",
    disabled: true,
  },
  {
    id: 2,
    name: "Enabled",
  },
  {
    id: 0,
    name: "Disabled",
  },
];

export const user_levels = [
  {
    id: 0,
    name: "User",
  },
  {
    id: 1,
    name: "Admin",
  },
];

export const week_days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const day_times = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "00:00",
];

export const identifier_types = [
  {
    id: 1,
    name: "mobile",
  },
  {
    id: 0,
    name: "email",
  },
  {
    id: 2,
    name: "id",
  },
];

export const active_services_fields = [
  "Title",
  "Product",
  "Type",
  "Value",
  "Amount",
  "Billing",
  "Active since",
  "Expires",
  "Renews",
  "Status",
  "Action",
];

export const contacts_fields = [
  "",
  "Name",
  "Email",
  "Phone",
  "Mobile",
  "Address",
  "Channel Identifier",
  "Labels",
  "Action",
];

export const contact_events_fields = [
  "Type",
  "Event",
  "Inserted by",
  "Date",
];

export const notifications_fields = [
  "Title",
  "Message",
  "Date",
  "Action",
];

export const queues_fields = [
  "Queue",
  "Users",
  "Skills",
  "Languages",
  "Channels",
  "Status",
  "SLA Response / Resolution (Minutes)",
  "Action",
];

export const replies_fields = [
  "Title",
  "Message",
  "Category",
  "Action",
];

export const reports_fields = [
  "Title",
  "Type",
  "Sub Type",
  "Request Date",
  "Status",
  "Completion Date",
  "Action",
];

export const responses_fields = [
  "Ticket Id",
  "Contact",
  "User",
  "Type",
  "Comment",
  "Score",
  "Response Date",
];

export const skills_fields = ["Skill", "Category", "Action"];

export const slas_fields = [
  "Queue",
  "Max response time (minutes)",
  "Max resolution time (minutes)",
  "Action",
];

export const surveys_fields = [
  "Question",
  "Type",
  "Total",
  "Sent",
  "Opened",
  "Responded",
  "Score",
  "Start Date",
  "End Date",
  "Action",
];

export const tickets_fields = [
  "Ticket Id",
  "Queue",
  "Channel Account",
  "Contact",
  "User",
  "Status",
  "Open Date",
  "Skills",
  "Language",
  "Action",
];

export const user_tickets_fields = [
  "Ticket Id",
  "Queue",
  "Channel Account",
  "Contact",
  "Status",
];

export const transactions_fields = [
  "Date",
  "Type",
  "Reference Number",
  "Description",
  "Amount",
];

export const users_fields = [
  "",
  "Name",
  "Email",
  "Display Name",
  "Level",
  "Languages",
  "Skills",
  "Online Status",
  "Account Status",
  "Actions",
];

export const contact_event_types = [
  {
    name: "General Info",
    value: 0,
  },
  {
    name: "Event",
    value: 1,
  },
];

export const tutorial_steps = [
  "Profile",
  "Organization",
  "Queue",
  "User",
  "Channel",
  "Business hours",
  "Set up account",
];

export const statistic_fields = [
  "Total",
  "Closed",
  "Tickets per hour",
  "Average resolution time (sec)",
  "First response time (sec)",
  "First contact resolution (%)",
  "SLA (%)",
  "NPS",
  "CSAT",
  "CES",
];

export const api_tokens_fields = [
  "Token",
  "Type",
  "Read",
  "Write",
  "Expiration Date",
  "Action",
];

export const pie_colors = ["#124868", "#A2A2A2", "#af3a2d"];

export const ImportCustomerAPIStatus = [
  {
    status: 201,
    message: "Customer created successfully.",
  },
  {
    status: 400,
    message:
      "The request was unacceptable, often due to invalid JSON format.",
  },
  {
    status: 401,
    message: "No valid API key provided.",
  },
  {
    status: 500,
    message: "The service is temporarily unavailable.",
  },
];

export const UpdateCustomerAPIStatus = [
  {
    status: 200,
    message: "Customer updated successfully.",
  },
  {
    status: 400,
    message:
      "The request was unacceptable, often due to invalid JSON format.",
  },
  {
    status: 401,
    message: "No valid API key provided.",
  },
  {
    status: 500,
    message: "The service is temporarily unavailable.",
  },
];

export const CustomerAPIParams = [
  {
    field: "email",
    status: "optional",
    type: "string",
    description: "The email of the customer.",
    hasExample: false,
  },
  {
    field: "first_name",
    status: "optional",
    type: "string",
    description: "The first name of the customer.",
    hasExample: false,
  },
  {
    field: "last_name",
    status: "optional",
    type: "string",
    description: "The last name of the customer.",
    hasExample: false,
  },
  {
    field: "custom_id",
    status: "required",
    type: "string",
    description: "A unique id of the customer.",
    hasExample: false,
  },
  {
    field: "logo",
    status: "optional",
    type: "url",
    description: "The email of the customer.",
    hasExample: false,
  },
  {
    field: "identifiers",
    status: "optional",
    type: "array",
    description:
      "Array of the customer's channel identifiers. Each identifier must be in this format:",
    hasExample: true,
    example:
      "{\n type: The type of the identifier. (id, email, mobile)\n value: The actual value of the identifier.\n channel: The channel of the identifier. (facebook, instagram, whatsApp)  \n}",
  },
  {
    field: "labels",
    status: "optional",
    type: "array",
    description:
      "An array of custom labels. Each label must be in this format:",
    hasExample: true,
    example:
      "{\n label: The label you want to define.\n value: The actual value of the label.\n}",
  },
];

export const customerApiErrors = [
  {
    field: "custom id",
    type: "string",
    description: "The custom id of the customer the error occured.",
  },
  {
    field: "status",
    type: "numeric",
    description: "The status code of the error.",
  },
  {
    field: "message",
    type: "string",
    description:
      "A human-readable message providing more details about the error.",
  },
];
