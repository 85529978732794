import { useAuth } from "../context/AuthContext";
import { useResolvedPath, Navigate, Outlet } from "react-router-dom";

const AdminRoute = () => {
  let { user } = useAuth();
  const { pathname } = useResolvedPath();
  return user.level === 1 &&
    (pathname === "/plans" || pathname === "/transactions") ? (
    <Navigate to="/dashboard" />
  ) : (
    <Outlet />
  );
};

export default AdminRoute;
