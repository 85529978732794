import axios from "axios";
import DateHeader from "./DateHeader";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import ProfileLogo from "./Logos/ProfileLogo";
import * as routes from "../data/links/routes";
import { useAuth } from "../context/AuthContext";
import ProfileModal from "./Modals/ProfileModal";
import * as endpoints from "../data/links/endpoints";
import NotificationBell from "./Divs/NotificationBell";
import { systemErrors } from "../data/helpers/objects";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { signal, useSignalEffect } from "@preact/signals-react";
import {
  CustomTitle,
  PaymentButton,
  SpacedRow,
  StyledBadge,
  StyledLink,
  VerticalCenteredRow,
} from "../data/style/style";

export const orgImage = signal();

const PageTitle = (props) => {
  let { title, showBadge } = props;
  let { user } = useAuth();
  const [image, setImage] = useState(null);
  const [notAnchor, setNotAnchor] = useState(null);
  const [profileAnchor, setProfileAnchor] = useState(null);
  const [profileModalActive, setProfileModalActive] = useState(false);
  const [notificationModalActive, setNotificationModalActive] =
    useState(false);

  useSignalEffect(() => {
    setImage(orgImage.value);
  });

  useEffect(() => {
    getOrganizationImage();
  }, []);

  let handleNotificationModalChange = (event) => {
    setProfileModalActive(false);
    setNotificationModalActive(!notificationModalActive);
    setNotAnchor(event.currentTarget);
  };

  let handleProfileModalChange = (event) => {
    setNotificationModalActive(false);
    setProfileModalActive(!profileModalActive);
    setProfileAnchor(event.currentTarget);
  };

  let getOrganizationImage = async () => {
    axios
      .get(endpoints.GET_ORGANIZATION_IMAGE)
      .then((response) => {
        orgImage.value = response.data.image;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          showBadge(3, systemErrors.unauthorized);
          return;
        }

        if (error.response.status >= 500) {
          showBadge(3, systemErrors.system_error);
          return;
        }
      });
  };

  return (
    <SpacedRow pl={1} pr={1} mb={1} minWidth={"400px"}>
      <VerticalCenteredRow gap={1}>
        <ProfileLogo
          image={image}
          object={"organization"}
          size={"4.036rem"}
          point={false}
        />
        <Stack>
          <CustomTitle>{title}</CustomTitle>
          <DateHeader />
        </Stack>
      </VerticalCenteredRow>
      <VerticalCenteredRow gap={3}>
        {user.level === 2 && (
          <PaymentButton href={routes.PLANS}>Upgrade</PaymentButton>
        )}
        <StyledLink href={routes.SUPPORT}>
          <HelpCenterIcon
            sx={{
              fontSize: "2rem",
              color: "#124868",
              cursor: "pointer",
              transition: "500ms",
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
          />
        </StyledLink>
        {user.level > 0 && (
          <NotificationBell
            active={notificationModalActive}
            setActive={setNotificationModalActive}
            handleOpen={handleNotificationModalChange}
            anchor={notAnchor}
          />
        )}
        <StyledBadge
          status={user.online_status}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          badgeContent=" "
          overlap="circular"
        >
          <ProfileModal
            active={profileModalActive}
            setActive={setProfileModalActive}
            anchor={profileAnchor}
            handleOpen={handleProfileModalChange}
            size={"4.036rem"}
          />
        </StyledBadge>
      </VerticalCenteredRow>
    </SpacedRow>
  );
};

export default PageTitle;
