export const LOGIN = "/";
export const REGISTER = "register";
export const RESET_PASSWORD = "reset/:token";
export const VERIFY_EMAIL = "/verify/email/:token";
export const SET_UP = "setup/:token";
export const RECOVER_PASSWORD = "recover";

export const TUTORIAL = "tutorial";
export const DASHBOARD = "dashboard";
export const CONVERSATIONS = "conversations";
export const CONTACTS = "contacts";
export const CONTACTS_WITH_PARAM = "contacts/:id";
export const STATISTICS = "statistics";
export const REPORTS = "reports";
export const TICKET_TRANSCRIPTS = "transcripts";
export const TICKET_TRANSCRIPTS_WITH_PARAM = "transcripts/:id";
export const CEX_METRICS = "cex/metrics";
export const CEX_STATISTICS = "cex/statistics";
export const CHANNELS = "channels";
export const QUEUES = "queues";
export const USERS = "users";
export const USERS_WITH_PARAM = "users/:id";
export const SKILLS = "skills";
export const RULES = "rules";
export const BUSSINESS_HOURS = "businesshours";
export const SLAS = "slas";
export const AUTOMATED_REPLIES = "replies/automated";
export const PREPARED_REPLIES = "replies/prepared";
export const PLANS = "plans";
export const TRANSACTIONS = "transactions";
export const ORGANIZATION = "organization";
export const PROFILE = "profile";
export const NOTIFICATIONS = "notifications";
export const API_TOKENS = "tokens";
export const API_DOCS = "docs";

export const CEX_QUESTIONS = "cex/:hash";
export const TERMS = "terms";
export const SUPPORT = "support";
export const POLICY = "policy";
export const ERROR = "*";
