import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ResultIcon = (props) => {
  let { type_id } = props;
  return (
    <>
      {type_id === 2 ? (
        <CheckCircleIcon
          color="success"
          sx={{ fontSize: "1.1rem" }}
        />
      ) : type_id === 1 ? (
        <InfoIcon color="secondary" sx={{ fontSize: "1.1rem" }} />
      ) : (
        <HighlightOffIcon color="error" sx={{ fontSize: "1.1rem" }} />
      )}
    </>
  );
};

export default ResultIcon;
