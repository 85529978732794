import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Fade from "@mui/material/Fade";
import Loader from "../Display/Loader";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import Popper from "@mui/material/Popper";
import { useEffect, useState } from "react";
import { Theme } from "../../data/style/Theme";
import * as routes from "../../data/links/routes";
import Notification from "../Display/Notification";
import { StyledLink } from "../../data/style/style";
import ResponseBadge from "../Display/ResponseBadge";
import * as endpoints from "../../data/links/endpoints";
import { systemErrors } from "../../data/helpers/objects";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const NotificationBell = (props) => {
  let { handleOpen, active, setActive, anchor } = props;

  const [badgeText, setBadgeText] = useState("");
  const [requestStatus, setRequestStatus] = useState(0);
  const [badgeActive, setBadgeActive] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [hasMoreNot, setHasMoreNot] = useState(false);
  const [notificationsLoaded, setNotificationsLoaded] =
    useState(false);

  let handleErrors = (error) => {
    if (error.response.status === 401) {
      showBadge(3, systemErrors.unauthorized);
      return;
    }

    if (error.response.status >= 500) {
      showBadge(3, systemErrors.system_error);
      return;
    }
  };

  let getNotifications = async (page, limit, status) => {
    setNotificationsLoaded(false);
    var raw = {
      page: page,
      limit: limit,
      status: status,
    };

    axios
      .post(endpoints.GET_NOTIFICATIONS, raw)
      .then((response) => {
        setNotifications(response.data.notifications);
        // setHasMoreNot(response.data.has_more_notifications)
      })
      .catch((error) => handleErrors(error))
      .finally(() => setNotificationsLoaded(true));
  };

  const shutDownNotification = async (id) => {
    axios
      .get(endpoints.READ_NOTIFICATION + id)
      .then(() => {
        setNotifications(
          notifications.filter((item) => item.id !== id)
        );
      })
      .catch((error) => handleErrors(error));
  };

  useEffect(() => {
    getNotifications(1, 4, 1);
  }, []);

  let showBadge = (status, text) => {
    setRequestStatus(status);
    setBadgeActive(true);
    setBadgeText(text);
    setTimeout(() => {
      setBadgeActive(false);
    }, 2000);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setActive(false)}>
        <Box>
          <Badge
            invisible={notifications.length === 0 && !hasMoreNot}
            color="error"
            badgeContent=" "
            variant="dot"
          >
            <NotificationsActiveIcon
              onClick={handleOpen}
              sx={{
                fontSize: "2rem",
                color: `${Theme.palette.blue.dark}`,
                cursor: "pointer",
                transition: "500ms",
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
            />
          </Badge>

          {active ? (
            <Popper
              open={active}
              anchorEl={anchor}
              placement={"bottom-end"}
              transition
              key={1}
              sx={{ zIndex: 5 }}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Card
                    elevation={0}
                    sx={{
                      width: "350px",
                      padding: 1.5,
                      borderRadius: "10px",
                    }}
                  >
                    {notificationsLoaded ? (
                      <Stack
                        gap={1.5}
                        justifyContent={"space-between"}
                        minHeight={"150px"}
                      >
                        <Box height={"90%"}>
                          {notifications.map((item) => (
                            <Notification
                              shutDownNotification={
                                shutDownNotification
                              }
                              key={item.id}
                              item={item}
                            />
                          ))}
                        </Box>
                        <Stack alignItems={"center"}>
                          <StyledLink href={routes.NOTIFICATIONS}>
                            View More
                          </StyledLink>
                        </Stack>
                      </Stack>
                    ) : (
                      <Loader minHeight={150} />
                    )}
                  </Card>
                </Fade>
              )}
            </Popper>
          ) : null}
        </Box>
      </ClickAwayListener>
      <ResponseBadge
        requestStatus={requestStatus}
        open={badgeActive}
        responseText={badgeText}
      />
    </>
  );
};

export default NotificationBell;
