import axios from "axios";
import { signal } from "@preact/signals-react";
import * as routes from "../data/links/routes";
import { useNavigate } from "react-router-dom";
import { orgImage } from "../components/PageTitle";
import * as endpoints from "../data/links/endpoints";
import CircularProgress from "@mui/material/CircularProgress";
import { formErrors, systemErrors } from "../data/helpers/objects";
import {
  createContext,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  CenteredColumn,
  StyledLink,
  StyledText,
} from "../data/style/style";

const AuthContext = createContext();
export const profileImage = signal();

export const AuthProvider = (props) => {
  let { children } = props;
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [hasUser, setHasUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tokenError, setTokenError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [responseText, setResponseText] = useState("");
  const [requestStatus, setRequestStatus] = useState(0);
  const [tokenTextError, setTokenTextError] = useState();
  const [emailTextError, setEmailTextError] = useState();
  const [activeRequest, setActiveRequest] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [firstPassError, setFirstPassError] = useState(false);
  const [passwordTextError, setPasswordTextError] = useState();
  const [secondPassError, setSecondPassError] = useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [firstPassTextError, setFirstPassTextError] = useState("");
  const [secondPassTextError, setSecondPassTextError] = useState("");
  var regexPattern = new RegExp("true");
  const [displayNameTextError, setDisplayNameTextError] =
    useState("");
  const [autoHide, setAutoHide] = useState(
    regexPattern.test(localStorage.getItem("menuhide"))
  );

  let handleSwitch = () => {
    setAutoHide(!autoHide);
    localStorage.setItem("menuhide", !autoHide);
  };

  let showBadge = (status, text) => {
    setRequestStatus(status);
    setActiveRequest(true);
    setResponseText(text);
    setTimeout(() => {
      setActiveRequest(false);
    }, 4000);
  };

  let logoutUser = async () => {
    axios
      .get(endpoints.LOGOUT)
      .then(() => {
        setHasUser(false);
        orgImage.value = false;
        profileImage.value = false;
        setUser(null);
        navigate(routes.LOGIN);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          showBadge(3, systemErrors.unauthorized);
          return;
        }

        if (error.response.status >= 500) {
          showBadge(3, systemErrors.system_error);
          return;
        }
      });
  };

  let registerUser = async (email, password) => {
    var raw = {
      email: email,
      password: password,
    };

    axios
      .post(endpoints.REGISTER, raw)
      .then((response) => {
        showBadge(2, response.data.message);
        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 8000);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          setEmailError(true);
          setEmailTextError(
            <StyledText component={"span"} fail="true">
              There is an account already associated with this email.
              Reset your password&nbsp;
              <StyledLink href={routes.RECOVER_PASSWORD}>
                here
              </StyledLink>
              .
            </StyledText>
          );
        } else if (error.response.status === 400) {
          if (error.response.data.password) {
            setPasswordError(true);
            setPasswordTextError(error.response.data.password[0]);
            return;
          }

          if (error.response.data.email) {
            setEmailError(true);
            setEmailTextError(error.response.data.email[0]);
            return;
          }
        } else {
          if (error.response.status >= 500) {
            showBadge(3, systemErrors.system_error);
            return;
          }
        }
        setRequestStatus(0);
      });
  };

  let loginUser = async (email, password, page) => {
    var raw = {
      email: email,
      password: password,
    };

    axios
      .post(endpoints.LOGIN, raw)
      .then((response) => {
        console.log(response.data);
        setActiveRequest(true);
        setRequestStatus(2);
        setResponseText(response.data.message);
        setTimeout(() => {
          setHasUser(true);
          setUser(response.data.user);
          profileImage.value = response.data.user.hasLogo;
          if (
            response.data.user.setup === 1 &&
            response.data.user.level === 2
          ) {
            if (page) {
              navigate("/" + page, { replace: true });
            } else {
              navigate(routes.DASHBOARD, { replace: true });
            }
          } else {
            navigate(routes.TUTORIAL, { replace: true });
          }
          setActiveRequest(false);
          setRequestStatus(0);
        }, 2000);
      })
      .catch((error) => {
        setRequestStatus(3);
        if (error.response.status === 400) {
          if (error.response.data.password) {
            setPasswordError(true);
            setPasswordTextError(error.response.data.password[0]);
          }
          if (error.response.data.email) {
            setEmailError(true);
            setEmailTextError(error.response.data.email[0]);
          }
        } else if (error.response.status === 401) {
          showBadge(3, error.response.data.message);
        } else if (error.response.status === 406) {
          showBadge(3, systemErrors.seats_error);
        } else {
          showBadge(3, systemErrors.system_error);
        }
      });
  };

  let recoverPassword = async (email) => {
    var raw = { email: email };

    axios
      .post(endpoints.VERIFY_EMAIL, raw)
      .then((response) => {
        showBadge(2, response.data.message);
        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 8000);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          if (error.response.data.email) {
            setEmailTextError(error.response.data.email);
            setEmailError(true);
          }
        } else if (error.response.status === 404) {
          showBadge(3, "User not found.");
        } else {
          showBadge(3, systemErrors.system_error);
        }
        setRequestStatus(3);
      });
  };

  let resetPassword = async (password, c_password, token) => {
    var raw = {
      password: password,
      password_confirmation: c_password,
      token: token,
    };

    axios
      .post(endpoints.RESET_PASSWORD, raw)
      .then(() => {
        showBadge(2, "Password Updated.");
        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 3000);
      })
      .catch((error) => {
        setRequestStatus(3);
        if (error.response.status === 400) {
          if (error.response.data.password) {
            setFirstPassError(true);
            setFirstPassTextError(error.response.data.password[0]);
          }

          if (error.response.data.password_confirmation) {
            setSecondPassError(true);
            setSecondPassTextError(
              error.response.data.password_confirmation[0]
            );
          }
        }
        if (error.response.status === 404) {
          showBadge(3, error.response.data.error);
          return;
        }

        if (error.response.status === 406) {
          setFirstPassError(true);
          setSecondPassError(true);
          setSecondPassTextError(formErrors.invalid_password);
          return;
        }

        if (error.response.status === 409) {
          setFirstPassError(true);
          setSecondPassError(true);
          setSecondPassTextError(formErrors.different_passwords);
          return;
        }

        if (error.response.status >= 500) {
          showBadge(3, systemErrors.system_error);
          return;
        }
      });
  };

  let setUpAccount = async (display_name, pass, token) => {
    var raw = {
      display_name: display_name,
      password: pass,
      token: token,
    };

    axios
      .post(endpoints.COMPLETE_REGISTER, raw)
      .then(() => {
        showBadge(2, "Your account has been updated.");
        setTimeout(() => {
          navigate(routes.LOGIN);
        }, 3000);
      })
      .catch((error) => {
        if (error.response.data.password) {
          setPasswordError(true);
          setPasswordTextError(error.response.data.password[0]);
        } else {
          setPasswordError(false);
          setPasswordTextError("");
        }
        if (error.response.data.message) {
          showBadge(3, error.response.data.message);
        } else {
          setTokenError(false);
          setTokenTextError("");
        }

        if (error.response.data.displayname) {
          setDisplayNameError(true);
          setDisplayNameTextError(error.response.data.displayname[0]);
        } else {
          setDisplayNameError(false);
          setDisplayNameTextError("");
        }
      })
      .finally(() => {
        setTimeout(() => {
          setRequestStatus(0);
        }, 5000);
      });
  };

  let updateToken = async () => {
    axios
      .get(endpoints.REFRESH)
      .then((response) => {
        setUser(response.data.user);
        if (
          response.data.user.setup === 0 &&
          response.data.user.level === 2
        ) {
          navigate(routes.TUTORIAL);
        }
        profileImage.value = response.data.user.hasLogo;
        setHasUser(true);
      })
      .catch(() => {
        setHasUser(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }
    let minutes = 1000 * 60 * 6; // 6 minutes
    let interval = setInterval(() => {
      if (hasUser) {
        updateToken();
      }
    }, minutes);
    return () => {
      clearInterval(interval);
    };
  }, [hasUser, loading]);

  let contextData = {
    hasUser,
    loading,
    user,
    emailError,
    passwordError,
    firstPassError,
    secondPassError,
    passwordTextError,
    firstPassTextError,
    secondPassTextError,
    displayNameError,
    displayNameTextError,
    emailTextError,
    tokenError,
    tokenTextError,
    requestStatus,
    activeRequest,
    responseText,
    autoHide,

    loginUser,
    logoutUser,
    registerUser,
    recoverPassword,
    resetPassword,
    setUpAccount,
    setRequestStatus,
    handleSwitch,
    setAutoHide,
    setUser,
    setEmailError,
    setEmailTextError,
    setPasswordError,
    setPasswordTextError,
    setDisplayNameError,
    setDisplayNameTextError,
    setFirstPassError,
    setFirstPassTextError,
    setSecondPassError,
    setSecondPassTextError,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? (
        <CenteredColumn height={"100vh"}>
          <CircularProgress size={"30px"} color={"primary"} />
        </CenteredColumn>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthContext;
