import Box from "@mui/material/Box";
import logo from "../../assets/images/no-image.png";

const ProfileLogo = (props) => {
  let { image, size, func, point } = props;

  return (
    <Box
      onClick={func}
      sx={{
        width: size,
        minWidth: size,
        backgroundRepeat: "no-repeat",
        borderRadius: "50%",
        backgroundSize: "cover",
        cursor: point && "pointer",
        height: size,
        backgroundImage: `url(${image ? image : logo})`,
      }}
    />
  );
};
export default ProfileLogo;
